"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InstanceStatus = void 0;
/**
 * Instance
 */
var InstanceStatus;
(function (InstanceStatus) {
    InstanceStatus["enabled"] = "enabled";
    InstanceStatus["disabled"] = "disabled";
})(InstanceStatus = exports.InstanceStatus || (exports.InstanceStatus = {}));
