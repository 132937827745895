"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Package import
 */
const react_1 = require("@reduxjs/toolkit/query/react");
/**
 * Api
 */
const instancesApi = (0, react_1.createApi)({
    baseQuery: (0, react_1.fetchBaseQuery)({ baseUrl: process.env.INSTANCES_API }),
    endpoints: (builder) => ({
        getInstances: builder.query({
            query: () => 'list',
        }),
        createInstance: builder.mutation({
            query: (body) => ({
                url: 'add',
                method: 'POST',
                body
            }),
        }),
        deleteInstance: builder.mutation({
            query: (name) => ({
                url: 'disable',
                method: 'POST',
                body: {
                    short_name: name,
                }
            }),
        }),
    }),
});
exports.default = instancesApi;
