"use strict";
/*
 * Package Import
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BASE_URL = void 0;
/*
 * Local Import
 */
/**
 * URLs
 */
exports.BASE_URL = '/';
