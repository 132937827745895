"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadKeyCloak = void 0;
const loadKeyCloak = (callback) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.onload = () => {
        callback();
    };
    scriptTag.onerror = () => {
        alert('Failed to load Keycloak');
    };
    const scriptURL = new URL('/js/keycloak.js', process.env.KC_SERVER_URL);
    scriptTag.src = scriptURL.href;
    document.head.appendChild(scriptTag);
};
exports.loadKeyCloak = loadKeyCloak;
