"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginKC = void 0;
const RANGERS_ACCESS_ROLE = 'rangers_access';
const clientId = process.env.KC_CLIENT_ID || '';
const trueServerURL = new URL('/', process.env.KC_SERVER_URL).href;
const realm = process.env.KC_REALM || '';
const loginKC = (callback) => {
    if (window.Keycloak) {
        const kc = new window.Keycloak({
            "realm": realm,
            "url": trueServerURL,
            "clientId": clientId,
        });
        kc.init({
            checkLoginIframe: false,
        }).then((authenticated) => {
            var _a, _b;
            if (!authenticated) {
                kc.login();
            }
            else {
                // test access rights
                if ((_b = (_a = kc.resourceAccess[clientId]) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes(RANGERS_ACCESS_ROLE)) {
                    callback();
                }
                else {
                    alert('You do not have access rights to this application');
                    kc.logout();
                }
            }
        });
    }
    else {
        alert('Keycloak not loaded !');
    }
};
exports.loginKC = loginKC;
